import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LoginIcon from '@material-ui/icons/Input';

import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;

  return (
    <nav
      className={classes.navContainer}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={classes.navFullHeight}
          item
          xs={4}
          md={3}
        >
          <Box
            className={clsx(classes.navLinkLogo, classes.logoDesktop)}
          >
            <Link
              to="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
          <Box
            className={clsx(classes.navLinkLogo, classes.logoMobile)}
          >
            <Link
              to="/"
            >
              <img
                className={classes.logo}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
        />
        <Grid
          item
          xs={5}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {/* <Link to="/support">
            <div
              className={classes.linkContainer}
            >
              <Typography
                className={classes.linkNeutral}
                style={{
                  fontSize: '0.875rem',
                  fontFamily: 'Montserrat',
                  marginRight: '10px',
                }}
              >
                Resources
              </Typography>
              <MenuBookIcon
                className={classes.navIcon}
              />
            </div>
          </Link>
          <a
            className={classes.linkContainer}
            href={signInLink}
          >
            <Typography
              className={classes.linkNeutral}
              style={{
                fontSize: '0.875rem',
                fontFamily: 'Montserrat',
                marginRight: '10px',
              }}
            >
              Sign In
            </Typography>
            <LoginIcon
              className={classes.navIcon}
            />
          </a> */}
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
